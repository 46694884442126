<template>
  <v-card>
    <v-progress-linear
      :active="loading"
      color="blue"
      height="4"
      indeterminate
    />
    <v-card-text class="text-body-1">
      <InfoRow :text="$t('system.inventory')" :value="inventories"/>
      <InfoRow :text="$t('system.competition_analysis')" :value="competitionAnalysis"/>
      <InfoRow :text="$t('system.conversation_with_manager')" :value="conversationWithManager"/>
      <InfoRow :text="$t('system.order')" :value="order"/>
      <InfoRow :text="$t('system.order_total')" :value="`${orderTotal}zł`"/>
      <InfoRow :text="$t('system.freebies')" :value="freebies"/>
      <InfoRow :text="$t('system.gifts')" :value="gifts"/>
      <InfoRow :text="$t('system.service_settlement')" :value="serviceSettlement"/>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import InfoRow from "@/components/Visits/VisitSteps/InfoRow";
import notification from "@/notification/notify";

export default {
  name: 'SummaryStep',
  components: {InfoRow},
  props: {
    visitId: {
      type: [Number, String],
      required: true
    }
  },
  data: () => ({
    loading: false,
    competitionAnalysis: null,
    conversationWithManager: null,
    freebies: null,
    gifts: null,
    inventories: null,
    order: null,
    orderTotal: 0,
    serviceSettlement: null
  }),
  beforeMount() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        const {data} = await axios.get(`sk_visits/${this.visitId}/summary`)
        console.log('data', data)
        this.competitionAnalysis = data.data.competition_analysis
        this.conversationWithManager = data.data.conversation_with_manager
        this.freebies = data.data.freebies
        this.gifts = data.data.gifts
        this.inventories = data.data.inventories
        this.order = data.data.order
        this.orderTotal = data.data.order_total
        this.serviceSettlement = data.data.service_settlement
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load'),
          'error');
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
