<template>
  <p>{{ text }}: <b>{{ $t(computedValue) }}</b></p>
</template>

<script>
export default {
  name: 'InfoRow',
  props: {
    text: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    defaultValue: {
      type: String
    }
  },
  computed: {
    computedValue() {
      if (typeof this.value === 'boolean') {
        return `system.${this.value ? 'yes' : 'no'}`
      } else if (typeof this.value === 'string' && this.value.length) {
        return this.value
      } else {
        return this.defaultValue || ''
      }
    }
  }
}
</script>

<style scoped>

</style>
